






































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import NewDecoComponent from '../NewDecoComponent.vue';
import NewFishComponent from '../NewFishComponent.vue';
import NewSkinComponent from '../NewSkinComponent.vue';
import ProductButtonComponent from '@/components/shop/ProductButtonComponent.vue';

@Component({
  components: {
    NewDecoComponent,
    NewFishComponent,
    NewSkinComponent,
    ProductButtonComponent,
  },
})
export default class NewsPost0 extends Vue {
  public mounted() {
    this.$emit('mounted', 1);
  }
}
